/**
 * 示例-自定义组件-腾讯地图
 * luxinwen
 * 2023-05
 */
<template>
  <div class="page">
    <div class="page-main">
      <div class="page-main-content">
        <div class="page-main-content-thead">sp-map-t 腾讯地图</div>
        <div class="page-main-content-subhead">示例</div>
        <Card class="page-main-content-item">
          <sp-map-t :center="value"></sp-map-t>
          <Divider orientation="left" plain>基本用法</Divider>
          <pre>&lt;sp-map-t :center="value"&gt;&lt;/sp-map-t&gt;</pre>
          <p>设置属性 <span class="text-red">center</span>，可以显示指定中心点。</p>
        </Card>
        <Card class="page-main-content-item">
          <sp-map-t :point="point"></sp-map-t>
          <Divider orientation="left" plain>指定坐标</Divider>
          <pre>&lt;sp-map-t :point="point"&gt;&lt;/sp-map-t&gt;</pre>
          <p>设置属性 <span class="text-red">point</span>，可以显示指定坐标点。仅支持对象类型，格式如下：</p>
          <pre>{
  lng: 113.94,
  lat: 22.53
}</pre>
        </Card>
        <Card class="page-main-content-item">
          <div>
            <div class="inline">
              <Input v-model="input" class="width-m" />
            </div>
            <div class="inline">
              <Button type="info" @click="searchMap">查询</Button>
            </div>
          </div>
          <sp-map-t :center="value2" @on-change="changeMap"></sp-map-t>
          <Divider orientation="left" plain>获取回调坐标</Divider>
          <pre>&lt;sp-map-t :center="value" @on-change="changeMap"&gt;&lt;/sp-map-t&gt;</pre>
          <p>通过事件 <span class="text-red">on-change</span>，可以获取地址及坐标点信息。格式如下：</p>
          <p><span class="text-red">{{ mapResult }}</span></p>
        </Card>
        <div class="page-main-content-subhead">Props</div>
        <Table :columns="propsColumns" :data="propsData" border />
        <div class="page-main-content-subhead">Events</div>
        <Table :columns="eventsColumns" :data="eventsData" border />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        params: {
          'center': {
            tips: '中心点地址',
            type: 'String',
            default: ''
          },
          'point': {
            tips: '中心点坐标',
            type: 'Object',
            default: ''
          },
          'width': {
            tips: '图层宽度',
            type: 'String',
            default: '100%'
          },
          'height': {
            tips: '图层高度',
            type: 'String',
            default: '400px'
          },
          'disabled': {
            tips: '是否禁用',
            type: 'Boolean',
            default: 'false'
          }
        },
        events: {
          'on-change': {
            tips: '地址或坐标改变的回调，返回改变后的地址和坐标点数据',
            return: 'address：地址；lng：经度；lat：纬度'
          }
        },
        propsColumns: [
          {
            title: '属性',
            key: 'name',
            width: 140
          },
          {
            title: '说明',
            key: 'tips'
          },
          {
            title: '类型',
            key: 'type',
            width: 140
          },
          {
            title: '默认值',
            key: 'default',
            width: 160
          }
        ],
        eventsColumns: [
          {
            title: '事件名',
            key: 'name',
            width: 250
          },
          {
            title: '说明',
            key: 'tips'
          },
          {
            title: '返回值',
            render: (h, params) => {
              let children = [];
              params.row.return.split('；').forEach(item => {
                let p = [];
                item.split('：').forEach((key, index) => {
                  p.push(h('span', {
                    attrs: {
                      class: index === 0 ? 'text-red' : ''
                    }
                  }, key));
                });
                if (p.length === 2) {
                  p.splice(1, 0, h('span', '：'));
                }
                children.push(h('p', p));
              });
              return h('div', children);
            }
          }
        ],
        value: '广州市天河区',
        point: {
          lng: 113.94,
          lat: 22.53
        },
        input: '深圳市南山百度大厦',
        value2: '',
        mapResult: {}
      };
    },
    computed: {
      propsData() {
        let val = [];
        let data = this.params;
        Object.keys(data).forEach(key => {
          let item = Object.assign({}, data[key], {
            name: key
          });
          item.default = item.default || '-';
          val.push(item);
        });
        return val;
      },
      eventsData() {
        let val = [];
        let data = this.events;
        Object.keys(data).forEach(key => {
          let item = Object.assign({}, data[key], {
            name: key
          });
          val.push(item);
        });
        return val;
      }
    },
    methods: {
      /**
       * 地址或坐标改变的回调
       */
      changeMap(map) {
        this.mapResult = map;
      },
      /**
       * 查询地图
       */
      searchMap() {
        this.value2 = this.input;
      }
    }
  };
</script>